/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, Suspense } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    SnackbarAction,
    SnackbarProvider,
    withSnackbar,
    WithSnackbarProps,
} from 'notistack';
import { Client, User as IUser } from '@eql-ai/typescript-types';
import { Link, Button } from '@material-ui/core';
import {
    IdTokenResult,
    User,
    getIdTokenResult,
    onAuthStateChanged,
    signOut,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import adminRoutes from './routes/adminRoutes';
import store, { persistor, RootState } from './redux/adminStore';
import NotificationBar from './components/NotificationBar';
import * as AdminActionCreators from './redux/admin/adminActions';
import PrivateRoute from './components/PrivateRoute';
import './assets/css/IBMPlexSansFontFace.css';
import theme from './themes/adminTheme';
import RouteLoading from './components/RouteLoading';
import { AdminUser, Notification } from './redux/admin/adminState';
import { AUTH, FIRESTORE } from './config/FirebaseConfiguration';
import CoreCustomerDefault from './constants/CoreCustomerDefault';

interface AdminProps extends WithSnackbarProps {
    actions: typeof AdminActionCreators;
    isAuth: boolean | User;
    notification: Notification | null;
    hasWeakPassword: boolean;
}

const AdminController = (props: AdminProps) => {
    const {
        actions,
        isAuth,
        notification,
        enqueueSnackbar,
        closeSnackbar,
        hasWeakPassword,
    } = props;
    const [loading, setLoading] = useState(true);

    const getUserDetails = async (id: string): Promise<IUser> => {
        const fsRef = doc(FIRESTORE, 'users', id);
        const user = await getDoc(fsRef);
        return user.data() as IUser;
    };

    const getClientDetailsOfUser = async (
        clientId: string,
    ): Promise<Client> => {
        const docRef = doc(FIRESTORE, 'clients', clientId);
        const details = await getDoc(docRef);
        return {
            ...details.data(),
            id: details.id,
        } as Client;
    };

    const _signOut = async () => {
        await signOut(AUTH);
        actions.setAuthentication({
            isAuth: false,
            adminUser: null,
        });
        await persistor.purge();
        setLoading(false);
    };

    const persistAdminClientToReduxStore = async (adminClient: Client) => {
        try {
            const {
                name,
                id,
                flags,
                agreement_type,
                integration_type,
                phio_agent,
                is_internal_customer,
                clinical_services_enabled,
            } = adminClient;

            actions.setAdminClient({
                name,
                id: id!,
                flags: flags || [],
                agreementType: agreement_type || null,
                integrationType: integration_type || null,
                phioAgent: phio_agent || '',
                isInternalCustomer: is_internal_customer || false,
                clinicalServicesEnabled: clinical_services_enabled || false,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const setAuthObjectInReduxStore = async (authObj: {
        isAuth: User;
        adminUser: AdminUser;
    }) => {
        try {
            actions.setAuthentication(authObj);
        } catch (e) {
            console.log(e);
        }
    };

    const isReservedSuperUser = (param: IdTokenResult): boolean => {
        return (
            param.claims.role === 'super' ||
            param.claims.access_level === 'super'
        );
    };

    // Check IP
    // const isIpWhiteListed = async () => {
    //     try {
    //         const ipChecker = firebase.functions().httpsCallable('ipCheck');
    //         const checkResponse = await ipChecker();
    //         return checkResponse.data.status === 200;
    //     } catch (e) {
    //         console.log(e);
    //         return false;
    //     }
    // };

    useEffect(() => {
        const handleAuthStatusChange = async (user: User | null) => {
            // const isValidIp = await isIpWhiteListed();
            const isValidIp = true;
            if (!user) {
                actions.setAuthentication({
                    isAuth: false,
                    adminUser: null,
                });
                await persistor.purge();
                setLoading(false);
                return;
            }
            if (!isValidIp && !user.email?.match(/eql.ai/)) {
                actions.setNotification({
                    type: 'error',
                    content:
                        'It appears your IP address is not whitelisted. Please get in touch with provider',
                });
                await _signOut();
                return;
            }
            const tokenResult = await getIdTokenResult(user);

            if (!tokenResult) {
                await _signOut();
                return;
            }

            const { claims } = tokenResult;
            if (!claims.admin) {
                await _signOut();
                return;
            }
            const isAnEqlSuper = isReservedSuperUser(tokenResult);
            const userAccount = isAnEqlSuper
                ? ({
                      client_id: 'eql',
                      email: user.email,
                      first_name: user.displayName ?? 'Super',
                      last_name: '',
                      role: 'super',
                  } as IUser)
                : await getUserDetails(user.uid);
            const { client_id } = userAccount;
            await persistor.purge();

            if (hasWeakPassword) {
                // Snackbar Actions: change password now and dismiss buttons
                const action: SnackbarAction = (key) => (
                    <>
                        <Link href="/forgotPassword" component={Button}>
                            CHANGE NOW
                        </Link>
                        <Button onClick={() => closeSnackbar(key)}>
                            DISMISS
                        </Button>
                    </>
                );

                enqueueSnackbar(
                    "It appears that you're using a weak password. Please, update it ASAP as we'll enforce a new password policy soon.",
                    {
                        persist: true,
                        variant: 'warning',
                        action,
                    },
                );
            }

            const clientAccount = isAnEqlSuper
                ? (CoreCustomerDefault as Client)
                : await getClientDetailsOfUser(client_id);
            if (clientAccount) {
                await persistAdminClientToReduxStore(clientAccount);
            }
            const userRegion = tokenResult.claims.region as string;

            await setAuthObjectInReduxStore({
                isAuth: user,
                adminUser: {
                    uid: user.uid,
                    accessLevel: (claims.access_level ??
                        userAccount.role) as string,
                    region: userRegion ?? undefined,
                    firstName: userAccount.first_name,
                    lastName: userAccount.last_name,
                },
            });
            // Set the current region
            // This will be overridden by the region picker if the user is a super user
            actions.setCurrentRegion(userRegion);

            setLoading(false);
        };

        const unsubscribe = onAuthStateChanged(AUTH, handleAuthStatusChange);

        return () => unsubscribe();
        // eslint-disable-next-line
    }, [hasWeakPassword]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                {loading ? (
                    <div
                        style={{
                            position: 'absolute',
                            top: window.innerHeight / 2 - 100,
                            left: window.innerWidth / 2 - 100,
                            width: 200,
                            height: 200,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress
                            size={64}
                            style={{ color: '#4386f9' }}
                        />
                    </div>
                ) : (
                    <Suspense fallback={<RouteLoading />}>
                        <Switch>
                            {adminRoutes.map((route) =>
                                // todo - refactor this into something nicer
                                [
                                    'Login',
                                    'ForgotPassword',
                                    'ResetPassword',
                                ].includes(route.routeName) ? (
                                    <Route
                                        path={route.path}
                                        component={route.component}
                                        key={route.routeName}
                                        exact={route.exact}
                                    />
                                ) : (
                                    <PrivateRoute
                                        path={route.path}
                                        isAuth={isAuth}
                                        component={route.component}
                                        key={route.routeName}
                                    />
                                ),
                            )}
                        </Switch>
                    </Suspense>
                )}
            </Router>
            <NotificationBar notification={notification} />
        </ThemeProvider>
    );
};

const mapStateToProps = (state: RootState) => ({
    isAuth: state.adminReducer.isAuth,
    notification: state.adminReducer.notification,
    hasWeakPassword: state.adminReducer.hasWeakPassword,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    actions: bindActionCreators(AdminActionCreators, dispatch),
});

const ConnectedAdminController = withSnackbar(
    connect(mapStateToProps, mapDispatchToProps)(AdminController),
);

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SnackbarProvider maxSnack={3}>
                    <ConnectedAdminController />
                </SnackbarProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
